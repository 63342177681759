<template>
    <div>
       <div style="background: #F0F0F0;margin-top:40px">
         <div style="width: 1500px;margin: 0 auto;margin-top:110px;">
            <div style="height: auto;width: 1500px;">
                <div style="min-height: 100vh;width: 100%;margin: 0 auto;background-color: #FFFFFF;">
                    <div style="width: 100%;overflow: hidden;height: 64px;">
                        <div style="width: 0;height: 0;border-top-left-radius: 8px;border-bottom: 20px solid transparent;border-left: 20px solid #031f88;border-right: 20px solid transparent;border-top: 20px solid #031f88;float: left"></div>
                        <div class="hand op sc" @click="return_list" style="float:left;line-height: 65px; font-weight: bold; font-size: 20px;letter-spacing: 1px;height: auto;cursor: pointer"><i class="el-icon-back"></i>{{file_name}}</div>
                        <div style="width: 140px;height: 44px;float: right;margin-top: 10px">
                            <el-button v-if="file_type == 1 || file_type == 2 || file_type == 3 || file_type == 4" @click="a('/filebase/upload/'+0)" type="primary" class="hand op sc" style="background: #F98B15;border-color: #F98B15;box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);"><i class="el-icon-upload"></i>上传资料</el-button>
                            <el-button v-if="file_type == 7"  type="primary" class="hand op sc" @click="uploadpictures" style="background: #F98B15;border-color: #F98B15;box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);"><i class="el-icon-upload"></i>上传图片</el-button>
                            <el-button v-if="file_type == 8"  type="primary" class="hand op sc" @click="electronics()" style="background: #F98B15;border-color: #F98B15;box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);"><i class="el-icon-upload"></i>上传视频</el-button>
                            <el-button v-if="file_type == 9"  type="primary" class="hand op sc" @click="attachment(0)" style="background: #F98B15;border-color: #F98B15;box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);"><i class="el-icon-upload"></i>上传附件</el-button>
                        </div>
                        <div style="width: 700px;height: 40px;float: right;margin-top: 10px;margin-right: 20px;display: flex;">
                            <el-cascader style="width: 228px;margin-right: 20px;box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);border-radius: 5px"  v-model="kwtypearr" placeholder="请选择分类" @change="handleSelect" :options="type_list"></el-cascader>
                            <el-input placeholder="请输入内容" v-model="search" style="box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);border-radius: 5px">
                                <template slot="append">
                                    <el-button v-if="file_type == 1 || file_type == 2 || file_type == 3 || file_type == 4" type="primary" @click="get_content_list_like" class="hand op sc" style="background: #031F88;border-color: #031F88;border-radius: 0px 5px 5px 0px;height: 40px;"><i class="el-icon-search" style="color: #FFFFFF"></i></el-button>
                                    <el-button v-if="file_type == 7 || file_type == 8 || file_type == 9" type="primary" @click="get_content_list_search($route.params.file_type)" class="hand op sc" style="background: #031F88;border-color: #031F88;border-radius: 0px 5px 5px 0px;height: 40px;"><i class="el-icon-search" style="color: #FFFFFF"></i></el-button>
                                </template>
                            </el-input>
                        </div>
                        <div style="float: right;margin-top: 12px;">
                            <div v-if="is_login === 1" style="float: left;margin: 0 10px;">
                                <el-button v-if="file_type == 1 || file_type == 2 || file_type == 3 || file_type == 4"  size="medium" class="hand op sc" style="box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);" @click="getFilebaseList(6)">全部文章</el-button>
                                <el-button v-if="file_type == 7 || file_type == 8 || file_type == 9" size="medium" class="hand op sc" style="box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);" @click="getFilebaseList($route.params.file_type)">全部{{type_name}}</el-button>
                                <el-button v-if="file_type == 1 || file_type == 2 || file_type == 3 || file_type == 4"  size="medium" class="hand op sc" style="box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);margin-right: 10px" @click="uploaded(6)">我的上传</el-button>
                                <el-button v-if="file_type == 7 || file_type == 8 || file_type == 9" size="medium" class="hand op sc" style="box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);margin-right: 10px" @click="uploaded($route.params.file_type)">我的上传</el-button>
                                <el-button v-if="file_type == 1 || file_type == 2 || file_type == 3 || file_type == 4" size="medium" class="hand op sc" style="box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);margin-right: 10px" @click="getKwMyCollection(6)">我的收藏</el-button>
                                <el-button v-if="file_type == 7 || file_type == 8 || file_type == 9" size="medium" class="hand op sc" style="box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);margin-right: 10px" @click="get_KwMy_Collection($route.params.file_type)">我的收藏</el-button>
                                <el-button v-if="file_type == 1 || file_type == 2 || file_type == 3 || file_type == 4" size="medium" class="hand op sc" style="box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);margin-right: 10px" @click="getMyPayKnowledgeList(6)">我的购买</el-button>
                                <el-button v-if="file_type == 7 || file_type == 8 || file_type == 9" size="medium" class="hand op sc" style="box-shadow: 0px 1px 6px 1px rgba(0,0,0,0.16);margin-right: 10px" @click="getMyPayKnowledgeList($route.params.file_type)">我的购买</el-button>
                            </div>
                        </div>
                    </div>
                    <div><el-divider style="overflow:hidden;"></el-divider></div>
<!--                     <el-menu  :default-active="activeIndex" mode="horizontal" @select="handleSelect" style="overflow:hidden;float: left">-->
<!--                        &lt;!&ndash; 一级 &ndash;&gt;-->
<!--                         <el-submenu :index="item.value+''"  v-for="item in type_list" :key="item.value" style="margin-left:20px;overflow:hidden;">-->
<!--                                <template slot="title"><div style="font-size:18px;color: #303133;" >{{item.label}}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i></div></template>-->
<!--                                &lt;!&ndash; 二级 &ndash;&gt;-->
<!--                                <el-submenu  :index="item2.value+''" class="hand " v-for="item2 in item.children" :key="item2.value">-->
<!--                                    <template slot="title" ><div style="font-size:17px;color: #303133;">{{item2.label}}</div></template>-->
<!--                                &lt;!&ndash; 三级 &ndash;&gt;-->
<!--                                    <el-menu-item :index="item3.value+''" v-for="(item3,index) in item2.children" :key="index" :command=String(item3.value)><div style="font-size:17px;color: #303133;">{{ item3.label}}</div></el-menu-item>-->
<!--                                </el-submenu>-->
<!--                         </el-submenu>-->
<!--                    </el-menu>-->
                    <div style="overflow:hidden;width: calc(100% - 20px);padding: 0 10px 20px 10px;">
                    <div v-if="file_type != 9" >
                        <!--    文章    -->
                        <div v-if="file_type == 1 || file_type == 2 || file_type == 3 || file_type == 4" v-for="(item,index) in content_list" :key="index" style="width: calc((100% - 20px) / 2); margin: 10px 10px 10px 0;float: left">
                            <div style="position: relative;">
                                <FileCard :data="item"></FileCard>
                            </div>
                        </div>

                        <div v-if="file_type == 7 || file_type == 8" v-for="(item,index) in content_list" :key="index" style="width:352px;float: left;height: 356px;margin-left: 10px;margin-bottom: 20px;">
                             <!--     图片库的More  -->
                                 <div v-if="file_type == 7" style="width: 96%;margin: 2%;height:100% ;background-color: white;border: 1px solid #E4E4E4;box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);border-radius: 8px;overflow:hidden;">
                                     <div @click="content_info_type(item.id,item.payAmt,item.uuid,item.bgbType,item.PayStatus)" style="width: 100%; height: 65%;overflow: hidden;cursor: pointer;position: relative;">
                                         <div style="position: relative;">
                                             <img :src="item.cover" style="width:100%;height:229px;object-fit: cover">
                                         </div>
                                         <div v-if="item.payAmt > 0" style="position: absolute; top: 0; left: 0;z-index: 10;">
                                             <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/pay2.png" style="width: 80px;height: 80px;">
                                         </div>
                                         <div v-if="item.uuid != user.uuid">
                                             <img v-if="(item.payAmt > 0 && item.PayStatus == 1) || (item.payAmt == 0 && item.PayStatus == 0)" :src="item.cover" style="width:100%;height:229px;object-fit: cover">
                                             <img v-if="item.payAmt > 0 && item.PayStatus == 0" :src="item.cover" style="width:100%;height:229px;object-fit: cover;filter: blur(5px);">
                                         </div>
                                     </div>
                                     <div style="width: 100%;height: auto;">
                                         <div style="width: 60%;height:8%;color: #1a1a1a;float:left;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;padding: 8px 2% 0px 2%;cursor: pointer;font-weight: bold">{{item.title}}</div>
                                         <div >
                                             <div v-show="item.payAmt > 0 && item.PayStatus == 0" style="font-size: 12px;line-height: 29px;color: red;">付费金额:{{item.payAmt}}(元)</div>
                                         </div>
                                     </div>

                                     <div style="font-size: 14px;width:  96%;color: #6d737b;height:5%;margin-top: 5px;float:left;">
                                         <div style="float: right;text-align:right;line-height: 20px;margin-left: 2px;">{{item.createTime}}</div>
                                         <div style="float: left;line-height: 20px;"><span class="el-icon-view" style="font-size: 15px;color:#ff9800;padding-left:10px;"></span> {{item.clickCount}}</div>
                                     </div>
                                     <div style="width: 100%;height: calc(22% - 20px); border-top: 1px solid #CECFD0;margin-top: 10px;float:left;">
                                         <div v-if=" typeof item.user == 'undefined'" >
                                             <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
                                                 <img :src="item.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
                                             </div>
                                             <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.nickName }}</div>
                                         </div>
                                         <div v-else>
                                             <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
                                                 <img :src="item.user.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
                                             </div>
                                             <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.user.nickName }}</div>
                                         </div>
                                         <div v-if="item.isCollection == 0" @click="addFavorite(item.id,item.bgbType)" style="float: right;margin-top: 18px;font-size: 20px;margin-right: 8px;font-weight: bold;color: #00ff00 " class="el-icon-star-off hand"></div>
                                         <div v-else @click="cancelCollection(item.id,item.bgbType)" style="float: right;margin-top: 14px;font-size: 23px;margin-right: 8px;font-weight: bold;color:#00ff00" class="el-icon-star-on hand"></div>
                                     </div>
                                 </div>
                                <!--     视频库的More  -->
                                <div v-if="file_type == 8" style="width: 96%;margin: 2%;height:100%;border: 1px solid #E4E4E4;box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);position: relative;">
                                    <div @click="content_info_type(item.id,item.payAmt,item.uuid,item.bgbType,item.PayStatus)" style="width: 100%; height: 65%;overflow: hidden;cursor: pointer;position: relative;">
                                        <div>
                                            <img :src="item.cover" style="width:100%;height:229px;object-fit: cover;">
                                        </div>
                                        <div v-if="item.payAmt > 0 " style="position: absolute; top: 0; left: 0;z-index: 10;">
                                            <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/pay2.png" style="width: 80px;height: 80px;">
                                        </div>
                                        <div v-if="item.uuid != user.uuid">
                                            <img v-if="(item.payAmt > 0 && item.PayStatus == 1) || (item.payAmt == 0 && item.PayStatus == 0)" :src="item.cover" style="width:100%;height:229px;object-fit: cover">
                                            <img v-if="item.payAmt > 0 && item.PayStatus == 0" :src="item.cover" style="width:100%;height:229px;object-fit: cover;filter: blur(5px);">
                                        </div>
                                        <div style="margin-top: -145px;margin-left:135px;position: absolute;"><img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/menu/hongbao/icon13.png" style="width: 64px;height: 64px"></div>
                                    </div>
                                    <div style="width: 100%;height: auto;">
                                        <div style="width: 60%;height:8%;color: #1a1a1a;float:left;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;padding: 8px 2% 0px 2%;cursor: pointer;font-weight: bold">{{item.title}}</div>
                                        <div >
                                            <div v-show="item.payAmt > 0 && item.PayStatus == 0" style="font-size: 12px;line-height: 29px;color: red;">付费金额:{{item.payAmt}}(元)</div>
                                        </div>
                                    </div>
                                    <div style="font-size: 14px;width:  96%;color: #6d737b;height:5%;margin-top:5px;float: left;">
                                        <div style="float: right;text-align:right;line-height: 20px;margin-left: 2px;">{{item.createTime}}</div>
                                        <div style="float: left;line-height: 20px;"><span class="el-icon-view" style="font-size: 15px;color:#ff9800;padding-left:10px;"></span> {{item.clickCount}}</div>
                                    </div>
                                    <div style="width: 100%;height: calc(22% - 20px); border-top: 1px solid #CECFD0;margin-top: 10px;float: left;">
                                        <div v-if=" typeof item.user == 'undefined'" >
                                            <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
                                                <img :src="item.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
                                            </div>
                                            <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.nickName }}</div>
                                        </div>
                                        <div v-else>
                                            <div style="width: 26px;height: 26px;float: left;margin: 15px 0 0 8px ">
                                                <img :src="item.user.headIco" style="width: 100%;height: 100%;border-radius: 50%;">
                                            </div>
                                            <div style="font-size: 17px;color: #444444;float: left;margin: 16px 5px;font-weight: bold">{{ item.user.nickName }}</div>
                                        </div>
                                        <div v-if="item.isCollection == 0" @click="addFavorite(item.id,item.bgbType)" style="float: right;margin-top: 18px;font-size: 20px;margin-right: 8px;font-weight: bold;color: #00ff00 " class="el-icon-star-off hand"></div>
                                        <div v-else @click="cancelCollection(item.id,item.bgbType)" style="float: right;margin-top: 14px;font-size: 23px;margin-right: 8px;font-weight: bold;color:#00ff00" class="el-icon-star-on hand"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="content_list.length === 0" >
                           <NoData></NoData>
                        </div>

<!--                        <div v-if= "show_skeleton">-->
<!--                            <div v-for="item in 20" v-bind:key="item" style="width:20%; float: left;height: 47%;border-radius:15px 5px 0 0;">-->
<!--                                <div style="width: 96%;margin: 2%;height:100% ;background-color: white;border: 1px solid #E4E4E4;">-->
<!--                                    <Skeleton type="img" :show_skeleton ="show_skeleton"></Skeleton>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->

                    </div>
                        <!--     素材库   -->
                        <div v-if="file_type == 9">
                            <div id="enclosure_list" v-if="content_list.length>0">
                                <div class="col" v-for="number in [0,1,2,3]">
                                    <div class="folder" v-for="(item,index) in content_list"  :key="index"  v-if="index%4===number" >
                                        <el-popover trigger="hover" placement="bottom"  width="325" style="width: 325px;">
                                            <div v-if="is_login === 1">
                                                <div style="width: 100%;height: auto;text-align: center;font-size: 18px;font-weight: bold">{{item.title}}1111</div>
                                                <div class="fileList">
                                                    <div class="rolling">
                                                        <div class="file hand" v-for="(i,idx) in item.enclosure" :key="idx" style="height:100%; cursor: pointer;background: #e3d8d8;margin-bottom: 8px; overflow: hidden;border-radius: 3px;">
                                                            <div style="float: left;width: calc(100% - 50px);line-height: 40px;">
                                                                <div class="el-icon-paperclip" style="float: left;font-size: 16px;margin:0 3px;line-height: 40px;color: #67c23a"></div>
                                                                <el-tooltip effect="light" :content="i.name" placement="top" style="float: left;">
                                                                    <div style="width: 90%;overflow: hidden;text-overflow :ellipsis;white-space: nowrap;height: 40px;line-height: 40px;">{{i['name']}}</div>
                                                                </el-tooltip>
                                                            </div>
                                                            <el-button  @click="download(i['url'],i['name'],i['hash'],item.id,item.PayStatus,item.payAmt)" :disabled="isDownloading" style="border: none;background: #e3d8d8;width: 18px;height: 18px;"><i style="font-size: 18px;" class="el-icon-upload"></i></el-button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="folderImgAndName" slot="reference">
                                                <div class="folderImg" >
                                                    <i class="el-icon-folder-add"  style="font-size: 25px;color: #f8ae00"></i>
                                                    <!--		                        <i v-else class="el-icon-folder-remove"  style="font-size: 25px;color: #f8ae00"></i>-->
                                                </div>
                                                <div class="folder_img_url" v-if="item.isMe==0">
                                                    <el-tag type="success" v-if="item.PayStatus == 1 && item.paymentType == 2 " size="mini" effect="dark">已购</el-tag >
                                                    <el-tag type="danger" v-else-if="item.PayStatus == 0 && item.paymentType == 2 " size="mini" effect="dark">付费</el-tag >
                                                    <el-tag  v-else-if="item.PayStatus == 0 && item.paymentType == 0"  size="mini" effect="dark">免费</el-tag>

                                                </div>
                                                <div class="folder_img_url" v-if="item.isMe==1">
                                                    <el-tag type="info"  size="mini" effect="dark">我的</el-tag >
                                                </div>

                                                <div class="folderName hand">{{item.title}}</div>
                                                <!-- 收藏  -->
                                                <div v-if="item.isCollection == 0" @click="addFavorite(item.id,item.bgbType)" style="float: right;margin-top: 10px;font-size: 20px;margin-right: 5px;font-weight: bold;color: #00ff00 " class="el-icon-star-off hand"></div>
                                                <div v-else @click="cancelCollection(item.id,item.bgbType)" style="float: right;margin-top: 8px;font-size: 23px;margin-right: 5px;font-weight: bold;color:#00ff00" class="el-icon-star-on hand"></div>
                                                <label v-if="is_login == 1 && item.uuid === user.uuid">
                                                    <!--  删除  -->
                                                    <div style="float: right;margin-top: 10px;font-size: 18px;margin-right: 5px;font-weight: bold;color: red" class="el-icon-delete hand" @click="deleteEnclosure(item)"></div>
                                                    <!--  编辑  -->
                                                    <div style="float: right;margin-top: 10px;font-size: 18px;margin-right: 5px;font-weight: bold;color: #1C7EFF" class="el-icon-edit  hand" @click="attachment(1,item)"></div>
                                                </label>
                                            </div>
                                        </el-popover>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <el-dialog :close-on-click-modal="false" :visible.sync="Favorites">
                            <div style="padding: 20px; display: flex">
                                <span style="font-size: 18px;font-weight: 600;margin-top: 8px">标签名： </span>
                                <!--  <el-input style="width: 400px" v-model="labelName"></el-input>-->
                                <el-autocomplete
                                    style="width: 400px"
                                    v-model="labelName"
                                    :fetch-suggestions="querySearch"
                                    placeholder="请输入内容"
                                    @select="handleSelect_link">
                                    <template slot-scope="{ item }">
                                        <div class="name">{{ item.name }}</div>
                                    </template>
                                </el-autocomplete>
                            </div>
                            <div style="display: flex; justify-content: end">
                                <el-button  @click="cancelLabel">取消</el-button>
                                <el-button  @click="addcollect">确定</el-button>
                            </div>
                        </el-dialog>
                    </div>
                    <add-image-file-base-form :is-show="showAddImageFileBaseForm" :on-close="function (){showAddImageFileBaseForm = false;get_imgs_list();}"></add-image-file-base-form>
                    <el-dialog :close-on-click-modal="false" width="482px" :visible.sync="uploadvideo" :show-close=false class="upload_att">
                        <addUploadVideo ref="video_code" :on-close="function (){uploadvideo = false;get_videos_list();}"></addUploadVideo>
                    </el-dialog>
                    <el-dialog :visible.sync="uploadAttachment" width="705px" :close-on-click-modal="false" class="upload_att">
                        <addUploadAttachment ref="chment" :info=uploadAttachmentInfo></addUploadAttachment>
                    </el-dialog>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import FileCard from "./components/FileCard";
import addImageFileBaseForm from "@/components/common/addImageFileBaseForm";
import addUploadVideo from "@/components/common/addUploadVideo";
import addUploadAttachment from "@/components/common/addUploadAttachment";
import NoData from "@/components/common/NoData";
export default {
    name:"",
    components: {
        FileCard,
        addImageFileBaseForm,
        addUploadVideo,
        addUploadAttachment,
        NoData
    },
    computed: {
        user() {
            return this.$store.state.user
        },
        is_login(){
            return this.$store.state.is_login
        }
    },
    data () {
        return {
	        bgbType:6,
            file_name:"",
            file_type:0,
            content_list:[],
            more :1,
            page:1,
            show_skeleton:true,
            likecol:'',
            isShow: false,
            type_list:[],
            activeIndex: '1',
            type_id:"",
            codestring:'搜索',
            kwtypearr:0,
            type:"",
            nonedata:false,
            orderNo:"",
            isDownloading: false,
            // 搜索
            search:"",
            //   收藏
            Favorites:false,
            labelName:"",
            // 上传图片
            showAddImageFileBaseForm:false,
            // 视频
            uploadvideo:false,
            //  附件
            uploadAttachment:false,
            uploadAttachmentInfo:{
                id:0,
                auth:1,
                material_name:"",
                files:[],
                types:"",
                paytoread:false,
                input_paytoread:""
            },
            type_name:''
        }
    },
    props:{

    },
    created:function(){
        var that = this;
        //预支付订单监听
        this.$EventBus.$EventBus.$off("prePayOrder");
        this.$EventBus.$on("prePayOrder", (data) => {
            this.$nextTick(() => {
                if (data.code == 100) {
                    that.bankPay(data.toPay);
                }
            });
        });
    },
    mounted: function () {
        var that = this;
        // 目前附件暂时没有分类筛选
        if(that.file_type != 9) {
            that.get_type_list();
        }
        that.file_type = that.$route.params.file_type;
		if (that.file_type<6){
			that.bgbType = 6
		}else {
			that.bgbType = that.file_type;
		}
        if(that.file_type == 1){
            that.file_name="标准规范"
        }else if(that.file_type == 2){
            that.file_name="政策法规"
        }else if(that.file_type == 3){
            that.file_name="项目案例"
        }else if(that.file_type == 4){
            that.file_name="文章专著"
        }else if (that.file_type == 7){
            that.file_name="图片库"
        }else if (that.file_type == 8){
            that.file_name="视频库"
        }else if (that.file_type == 9){
            that.file_name="素材库"
        }
        document.title = that.file_name+'More页';
        if (that.file_type == 7){
            that.type_name="图片"
        }else if (that.file_type == 8){
            that.type_name="视频"
        }else if (that.file_type == 9){
            that.type_name="素材"
        }

        that.get_content_list();
        // 跳转在顶部
        window.scrollTo(0, 0);
        window.onscroll = function(){
            //变量scrollTop是滚动条滚动时，距离顶部的距离
            var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
            //滚动条到底部的条件
            var ratio = 0;
            if(ratio <= 1){
                if(scrollTop+windowHeight >= scrollHeight){
                    if (that.more == 1) {
                        that.get_content_list();
                    }else{
                        that.sus("已加载完");
                    }
                }
            }else{
                if(scrollHeight - scrollTop-windowHeight  < 10){
                    if (that.more == 1) {
                        that.get_content_list();
                    }else{
                        that.sus("已加载完");
                    }
                }
            }
        };
        var that = this;
        //监听支付成功
        this.$EventBus.$EventBus.$off("payMsg");
        this.$EventBus.$on("payMsg", (data) => {
            this.$nextTick(() => {
                if (data.code == 100) {
                    that.get_content_list();
                }
            });
        });
    },
     methods:{
         //   返回按钮事件
         return_list() {
             this.$router.go(-1);
         },
         //立即支付
         bankPay(data){
             var that = this;
             //判断是对公还是公绑私
             if(data.activeName != 'corporate'){
                 var params = {};
                 params.orderNo = data.orderNo;
                 params.code = data.code;
                 params.payPwd = data.payPwd;
                 that.newApi.settleAccountTransferDetermine(params).then(() => { });
             }
         },
        // 文章
      content_info:function(id){
	     var that = this;
        if (!this.utils.toAuth(1)) {
          return false
        }
                    window.open('/content/info/'+id+"/2");
	    },
        // 图片 视频
         content_info_type:function(id,payAmt,uuid,bgbType,PayStatus){
                 var that = this;
                 that.kwId = id;
                 that.clickBgbType = bgbType;
           if (!this.utils.toAuth(1)) {
             return false
           }
                     that.utils.a('/content/picture/'+id);
            },

        handleSelect(kwtypearr){
            this.page = 1;
            this.more = 1;
            this.kwtypearr = kwtypearr;
            this.content_list =  [];
            this.get_content_list();
         },
        //  初始化查询
        get_content_list:function (){
            var that = this;
            that.show_skeleton = true;
            var kwtypearr_third_level = that.kwtypearr.length >= 3 ? that.kwtypearr[2] : 0;
            that.newApi.getFilebaseList({
                page:that.page,
                pageSize:40,
	            bgbType:that.bgbType,
				kwType:kwtypearr_third_level,
                type:that.$route.params.file_type,
            }).then((res)=>{
                    for (var i = 0; i < res.data.length; i++) {
                        that.content_list.push(res.data[i]);
                    }
                    if (res.data.length < 20) {
                        that.more = 0;
                    }
                    if(res.data.length == 0){
                        that.nonedata = true
                    }else {
                        that.nonedata = false
                    }
                    that.page++;
                    that.show_skeleton = false;
            }).catch((err)=>{
                console.log(err)
            })
        },
         // 点击查询全部
         getFilebaseList(kwtypearr){
             var that = this;
             this.newApi.getFilebaseList({
                 page: 1,
                 pageSize: 40,
                 bgbType:kwtypearr,
                 like:"",
             }).then((res)=>{
                 if (res.isSuccess == 1){
                     that.content_list = res.data;
                 }
             }).catch((err)=>{
                 console.log(err)
             })
         },
         // 分类
         get_type_list(){
            var that = this;
            this.newApi.getTypeList({
                lv:3,
                is_good:0
            }).then((res)=>{
                that.type_list = res.data;
            }).catch((err)=>{
                console.log(err)
            })
         },
         // 附件下载
         download(url,name,hash,id,PayStatus,payAmt){
           if (!this.utils.toAuth(1)) {
             return false
           }
                 if (PayStatus == 0 && payAmt > 0){
                     this.payKnowledge(id)
                 }else {
                     this.isDownloading = true;  //禁用按钮
                     this.utils.download(url,name,hash)
                     setInterval(() => {
                         this.isDownloading = false;
                     }, 3000)
                 }
         },
         payKnowledge(id){
             var that = this;
             that.newApi.payKnowledge({
                 knowledgeId:id,
                 paidUuid:that.user.uuid,
             }).then((res)=>{
                 if (res.isSuccess == 1) {
                     // <AaDaPayCode ref="aDaPayCode" type="2" payType="14"  :memberId="paidKnowledgeinfo.memberId"  :payAmt="paidKnowledgeinfo.payAmt" :collectionUuid="paidKnowledgeinfo.addUuid" :orderNo="orderNo" @change="changeOrder($event)"></AaDaPayCode>
                     let params = that.$store.state.payParams;
                     params.type = 2;
                     params.payType = 14;
                     params.payAmt = res.data.payAmt;
                     params.collectionUuid = res.data.addUuid;
                     params.memberId = res.data.memberId;
                     params.orderNo = res.data.orderNo;
                     that.sign = that.utils.random_string(6);
                     that.newApi.addAndSavePrePayOrder({type:1,payParams:JSON.stringify(params),sign:that.sign}).then(() => {
                         that.b('/newAdaPay/?sign='+that.sign);
                     })
                 }
             }).catch((err)=>{
                 console.log(err)
             })
         },

         // 我收藏的
         getKwMyCollection(bgbType){
             var that = this;
             that.newApi.getKwMyCollection({
                 bgbType:bgbType,
                 page: 1,
                 pageSize: 40,
             }).then((res)=>{
                 if (res.isSuccess == 1) {
                     that.content_list = res.data;
                 }
             }).catch((err)=>{
                 console.log(err)
             })
         },
         get_KwMy_Collection(bgbType){
             var that = this;
             that.newApi.getKwMyCollection({
                 bgbType:bgbType,
                 page: 1,
                 pageSize: 40,
             }).then((res)=>{
                 if (res.isSuccess == 1) {
                     that.content_list = res.data;
                 }
             }).catch((err)=>{
                 console.log(err)
             })
         },
         //  文章搜索
         get_content_list_like(){
             var that = this;
             this.newApi.getFilebaseList({
                 page: 1,
                 pageSize: 40,
                 bgbType:6,
                 like:that.search
             }).then((res)=>{
                 if (res.isSuccess == 1){
                     that.utils.sus("搜索成功")
                     that.content_list = res.data;
                     that.search = ""
                 }
             }).catch((err)=>{
                 console.log(err)
             })
         },
         get_content_list_search(bgbType){
             var that = this;
             this.newApi.getFilebaseList({
                 page: 1,
                 pageSize: 40,
                 bgbType:bgbType,
                 like:that.search
             }).then((res)=>{
                 if (res.isSuccess == 1){
                     that.utils.sus("搜索成功")
                     that.content_list = res.data;
                     that.search = ""
                 }
             }).catch((err)=>{
                 console.log(err)
             })
         },
         addFavorite(id,bgbtype) {
             if(this.is_login === 0){
                 this.$store.commit('setShowLoginPopup', true);
                 return
             }
             this.Favorites = true;
             this.selectedItemId = id;
             this.selectedbgbtype = bgbtype
             this.getUserCollectionLabelList()
         },
         // 取消标签
         cancelLabel() {
             this.labelName = '';
             this.Favorites = false;
         },
         // 收藏
         addcollect(){
             this.newApi.addCollection({
                 bgbType: this.selectedbgbtype,
                 label: this.labelName,
                 id: this.selectedItemId
             }).then(res => {
                 if (res.isSuccess == 1) {
                     this.labelName = '';
                     this.Favorites = false;
                     this.getFilebaseList(this.$route.params.file_type)
                     this.utils.sus(res.data);
                 }
             })
         },
         async getUserCollectionLabelList() {
             try {
                 const response = await this.newApi.getUserCollectionLabelList({});
                 if (response.isSuccess === 1) {
                     this.restaurants = response.data;
                 }
             } catch (error) {
                 console.error(error);
             }
         },
         async querySearch(queryString, cb) {
             if (this.restaurants.length === 0) {
                 // 如果填写的数据为空，先加载数据
                 await this.getUserCollectionLabelList();
             }

             // 进行搜索
             const results = queryString
                 ? this.restaurants.filter(restaurant =>
                     restaurant.name.toLowerCase().includes(queryString.toLowerCase())
                 )
                 : this.restaurants;

             // 调用 callback 返回建议列表的数据
             cb(results);
         },
         handleSelect_link(item) {
             this.labelName = item.name; // 将选择的名称赋值给 labelName
             this.selectedRestaurant = item;
         },
        //  附件删除
         deleteEnclosure(item){
             var that = this;
             this.utils.confirm("确定删除吗？", () => {
                 that.newApi.delKnowledge({
                     id:item.id,

                 }).then((res)=>{
                     if (res.isSuccess == 1) {
                         that.utils.sus(res.data);
                     }
                 }).catch((err)=>{
                     console.log(err)
                 })
             })
         },
        //  附件编辑
         attachment(type,item){
             var that = this;
           if (!this.utils.toAuth(1)) {
             return false
           }
                 if (type==1){
                     if (item.paymentType != 0){
                         //消息提示
                         this.utils.confirm("付费素材不可编辑");return false;
                     }
                     that.uploadAttachmentInfo.auth = item.auth;
                     that.uploadAttachmentInfo.files = item.enclosure;
                     that.uploadAttachmentInfo.material_name = item.title;
                     that.uploadAttachmentInfo.types = JSON.parse(item.kwTypeArr);
                     that.uploadAttachmentInfo.id = item.id;
                 }
                 this.uploadAttachment  = true;

         },
         uploadpictures(){
             var that = this;
           if (!this.utils.toAuth(1)) {
             return false
           }
                 that.showAddImageFileBaseForm = true
         },
         electronics(){
             var that = this;
           if (!this.utils.toAuth(1)) {
             return false
           }
                 this.uploadvideo = true
         },
         complete(){
             this.uploadAttachment = false;
             this.uploadAttachmentInfo.id =0;
             this.uploadAttachmentInfo.auth =1;
             this.uploadAttachmentInfo.files = [];
             this.uploadAttachmentInfo.material_name = "";
             this.uploadAttachmentInfo.paytoread = false;
             this.uploadAttachmentInfo.input_paytoread = "";
             this.uploadAttachmentInfo.types = "";
         },
         contentvideo (){
             this.uploadvideo = false
             this.$refs.video_code.videos = [];
             this.$refs.video_code.inputvideo = "";
             this.$refs.video_code.types = "";
         },
         // 我上传的图片
         uploaded(bgbType){
             var that = this;
             that.newApi.getKwMyUpload({
                 bgbType:bgbType,
                 page: 1,
                 pageSize: 40,
             }).then((res)=>{
                 if (res.isSuccess == 1) {
                     that.content_list = res.data;
                 }
             }).catch((err)=>{
                 console.log(err)
             })
         },
        //  我购买的
         getMyPayKnowledgeList(bgbType){
             var that = this;
             that.newApi.getMyPayKnowledgeList({
                 bgbType:bgbType,
             }).then((res)=>{
                 if (res.isSuccess == 1) {
                     that.content_list = res.data;
                 }
             }).catch((err)=>{
                 console.log(err)
             })
         },
         // 取消收藏
         cancelCollection(id,bgbType){
             this.newApi.cancelCollection({
                 id: id,
                 bgbType: bgbType,
             }).then(res =>{
                 if (res.isSuccess == 1) {
                     this.utils.sus(res.data)
                     this.getFilebaseList(this.$route.params.file_type)
                 }
             })
         }
    },
}

</script>
<style lang="scss" scoped>

#enclosure_list{
    margin-bottom: 10px;height: auto;display: flex; gap:11px;
    padding:5px 30px; background: #FFF;
    overflow: hidden;
    .col{
        flex:1;
        .folder{
            width: 100%;
            margin-bottom: 10px;
            padding : 10px 0;
            background: #f4f4f4;
            box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);
            border-radius: 8px;
            .folderImgAndName{
                overflow: auto;
                height: 40px;
                margin-left: 5px;
                .folderImg{
                    width: 26px;height: 24px; margin: 7.5px;
                    float: left;
                }
                .folder_img_url{
                    width: 32px;
                    float: left;
                    line-height: 40px;
                    margin-right: 10px;
                }
                .folderName{
                    float: left;color: #555555;font-size:17px;text-overflow :ellipsis;overflow: hidden;
                    white-space :nowrap;margin-left: 2.5px;font-weight: bold;
                    height:40px;line-height: 40px;
                    width: 160px;
                }
            }
            .folder_border {
                float: left;
                width: 100%;
                border-top: 1px solid #CECFD0;
            }
            .folder_more {
                clear: both;
                text-align: center;
                padding-top: 10px;;
                font-size: 14px;
                cursor: pointer
            }
            .fileList{
                width: 330px;
                margin: 0 auto;
                height: 40px;
                .file{
                    cursor: pointer;background: #e3d8d8;margin-bottom: 8px; overflow: hidden;border-radius: 3px;
                    img{
                        width:22px;height:26px;float: left;
                        margin: 11px 10px 11px 16px;
                    }
                    span{
                        height:48px;line-height:48px; width:245px;color:#000;float: left; text-overflow :ellipsis;white-space :nowrap;overflow : hidden;
                    }
                    i {
                        float: left;
                    }
                }

            }

        }
    }
}
.zhishi {
    margin-top:-40px;
}

.title{
    overflow: hidden;
    overflow-y: scroll;
    max-height: 300px;
    line-height: 26px;
}
.title::-webkit-scrollbar {
    display: none;
}

.Fcti {
    width: 100%;
    background-color: #c2c2c266;
    border-radius: 5px 5px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 40px;
}
.icon_list {
    width: 0;
    height: 0;
    border-top-left-radius: 8px;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #031f88;
    border-right: 20px solid transparent;
    border-top: 20px solid #031f88;
}
.button {
    width: 120px;
    height: 38px;
    background:#F1F1F1;
    border-radius: 20px;
    display: flex;
    justify-content: center;
}
.bottom_name {
    width: 72px;
    height: 21px;
    font-size: 18px;
    color: #444444;
}
.rolling {
    max-height:500px;
    overflow-y: scroll;
}
.rolling::-webkit-scrollbar {
    display: none;
}

</style>
<style scoped>
.label{
    height: 28px;
    line-height: 28px;
    width:9%;
    background-color: #EEEEEE;
    margin-right: 0.5%;
    float: left;
    border-radius: 5px;
}
.el-menu.el-menu--horizontal{
    border-bottom:none
}
.el-submenu [class^=el-icon-] {
    margin-right: -2px;
    width: 2px;
}
/deep/ .el-empty__image {
    width: 260px;
}
.col {
    flex:1;
}
.folder{
    width: 100%;
    margin-bottom: 10px;
    padding : 10px 0;
    background: #f4f4f4;
    box-shadow: 0 0 8px 3px rgba(0,0,0,0.2);
    border-radius: 8px;
}
.fileList{
    width: 330px;
    margin: 0 auto;
    height: 40px;
}
.file {
    cursor: pointer;
    background: #e3d8d8;
    margin-bottom: 8px;
    overflow: hidden;
    border-radius: 3px;
}
.file .img {
    width:22px;
    height:26px;
    float: left;
    margin: 11px 10px 11px 16px;
}
.file .span {
    height:48px;
    line-height:48px;
    width:245px;
    color:#000;
    float: left;
    text-overflow :ellipsis;
    white-space :nowrap;
    overflow : hidden;
}
.file .i {
    float: left;
}

/deep/ .el-divider--horizontal {
    margin: 0px 0px;
}
</style>
<style>
.upload_att .el-dialog__body {
    padding: 28px 2px !important;
}
.upload_att .el-dialog__header {
    padding: 0px !important;
}
</style>
