<template>
  <div style="width: 100%; border-radius: 5px; box-shadow: 0px 2px 8px 1px rgba(0,0,0,0.16);background: #FFFFFF; position: relative">
    <div v-if="data.PayStatus === 1" style="border-top: 38px solid #69BF29;border-radius:0 5px 0 0;border-left: 38px solid transparent;position:absolute;top:0;right:0;width: 0; height: 0"></div>
    <div v-if="data.PayStatus === 1" style="position:absolute;top:4px;right:2px;color: #FFFFFF; font-size: 10px">已购</div>
    <div style="padding: 20px; display: flex; justify-content: space-between">
      <img class="hand op" @click="content_info(data.id)" v-if="data.cover" :src="data.cover" style="width: 150px; height: 100px; margin-right: 12px; border-radius: 5px">
      <div style="width: 72%">
        <div class="hand op" @click="content_info(data.id)" style="overflow: hidden;height: 64px">
          <img src="@/assets/imgs/other/buy.png" style="width: 22px; height: 22px; margin-right: 4px;float: left" v-if="data.paymentType !== 0">
          <span style="font-size: 16px;font-weight: 600;overflow: hidden;text-overflow: ellipsis; display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;/">
            {{data.title}}
          </span>
        </div>
        <div style="display: flex; justify-content: space-between; margin-top: 10px">
          <div>
            <span style="color: #999999; margin-right: 10px">{{data.createTime.substring(0, 10)}}</span>
            <span v-if="data.kwTypeArrName" style="color: #031F88">{{data.kwTypeArrName[0]}}>{{data.kwTypeArrName[1]}}>{{data.kwTypeArrName[2]}}</span>
          </div>
          <div class="hand op" style="display: flex" v-if="data.isCollection === 0" @click="addFavorite(data.id,data.bgbType)">
            <img src="@/assets/imgs/other/noCollection.png" style="width: 16px; height: 16px; margin: 3px 4px 0 0">
            <span>收藏</span>
          </div>
          <div class="hand op" style="display: flex" @click="cancelCollection(data.id,data.bgbType)" v-else>
            <img src="@/assets/imgs/other/isCollection.png" style="width: 16px; height: 16px; margin: 4px 4px 0 0">
            <span style="color: #FF9800">已收藏</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :close-on-click-modal="false" :visible.sync="Favorites">
      <div style="padding: 20px; display: flex">
        <span style="font-size: 18px;font-weight: 600;margin-top: 8px">标签名： </span>
        <!--  <el-input style="width: 400px" v-model="labelName"></el-input>-->
        <el-autocomplete
            style="width: 400px"
            v-model="labelName"
            :fetch-suggestions="querySearch"
            placeholder="请输入内容"
            @select="handleSelect">
          <template slot-scope="{ item }">
            <div class="name">{{ item.name }}</div>
          </template>
        </el-autocomplete>
      </div>
      <div style="display: flex; justify-content: end">
        <el-button  @click="cancelLabel">取消</el-button>
        <el-button  @click="addcollect">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {
  },
  computed: {
    user(){
      return this.$store.state.user;

    },
    is_login(){
      return this.$store.state.is_login
    },
  },
  props: {
    data: {
    },
  },
  data() {
    return {
      Favorites:false,
      labelName:"",
      restaurants:[],
      selectedItemId: '',
      selectedbgbtype: '',
      selectedRestaurant: {}
    }
  },
  watch: {

  },
  mounted: function () {

  },
  methods: {
    content_info: function (id) {
      var that = this;
      if (!this.utils.toAuth(1)) {
        return false
      }
        window.open('/content/info/' + id + "/2");
    },

    addFavorite(id, bgbType) {
       if(this.is_login === 0){
          this.$store.commit('setShowLoginPopup', true);
          return
      }
      this.Favorites = true;
      this.selectedItemId = id;
      this.selectedbgbtype = bgbType
      this.getUserCollectionLabelList()
    },

    // 收藏
    addcollect(){
      this.newApi.addCollection({
        bgbType: this.selectedbgbtype,
        label: this.labelName,
        id: this.selectedItemId
      }).then(res => {
        if (res.isSuccess == 1) {
          this.labelName = '';
          this.Favorites = false;
          this.utils.sus(res.data);
          this.$parent.get_content_list();
          this.data.isCollection = 1;
        }
      })
    },
    // 取消标签
    cancelLabel() {
      this.labelName = '';
      this.Favorites = false;
    },

    async getUserCollectionLabelList() {
      try {
        const response = await this.newApi.getUserCollectionLabelList({});
        if (response.isSuccess === 1) {
          this.restaurants = response.data;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async querySearch(queryString, cb) {
      if (this.restaurants.length === 0) {
        // 如果填写的数据为空，先加载数据
        await this.getUserCollectionLabelList();
      }

      // 进行搜索
      const results = queryString
          ? this.restaurants.filter(restaurant =>
              restaurant.name.toLowerCase().includes(queryString.toLowerCase())
          )
          : this.restaurants;

      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    handleSelect(item) {
      this.labelName = item.name; // 将选择的名称赋值给 labelName
      this.selectedRestaurant = item;
    },
      // 取消收藏
      cancelCollection(id,bgbType){
          this.newApi.cancelCollection({
              id: id,
              bgbType: bgbType,
          }).then(res =>{
              if (res.isSuccess == 1) {
                  this.data.isCollection = 0;
                  this.utils.sus(res.data)
              }
          })
      }
  }
}
</script>

<style scoped>

</style>
